<template>
	<div>
		<AddClient />
		<ClientsTable />
	</div>
</template>


<script>
import AddClient from '@/components/molecules/AddClient.vue'
import ClientsTable from '@/components/templates/ClientsTable.vue'
export default {
	components: {
		ClientsTable,
		AddClient
	}
}
</script>